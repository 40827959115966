import { KIDS_DEPARTMENTS, ProductDefinitionOption, toKebapCase } from './productTypeDefinitions';

export const clothingSizeStandardOptions: Array<ProductDefinitionOption> = [
  'INT',
  'EU',
  'UK',
  'US',
  'IT',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) =>
    !KIDS_DEPARTMENTS.includes(department) && category === 'clothing',
}));

export const shoesSizeStandardOptions: Array<ProductDefinitionOption> = [
  'EU',
  'UK',
  'US',
  'IT',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) =>
    !KIDS_DEPARTMENTS.includes(department) && category === 'shoes',
}));
