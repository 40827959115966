import { ProductDefinitionDataForm } from './EditListingCategoryAndSizeForm';
import {
  kidsBeltSizeOptions,
  kidsClothingSizeOptions,
  kidsGlovesSizeOptions,
  kidsHatSizeOptions,
  kidsShoesSizeOptions,
} from './productSizingOptionsKids';
import {
  euMenClothingSizeOptions,
  euMenShoesSizeOptions,
  intMenClothingSizeOptions,
  itMenClothingSizeOptions,
  itMenShoesSizeOptions,
  menBeltSizeOptions,
  menGlovesSizeOptions,
  menHatSizeOptions,
  menRingSizeOptions,
  ukMenClothingSizeOptions,
  ukMenShoesSizeOptions,
  usMenClothingSizeOptions,
  usMenShoesSizeOptions,
} from './productSizingOptionsMen';
import {
  euWomenClothingSizeOptions,
  euWomenShoeSizeOptions,
  intWomenClothingSizeOptions,
  itWomenClothingSizeOptions,
  itWomenShoeSizeOptions,
  ukWomenClothingSizeOptions,
  ukWomenShoeSizeOptions,
  usWomenClothingSizeOptions,
  usWomenShoeSizeOptions,
  womenBeltSizeOptions,
  womenGloveSizeOptions,
  womenHatSizeOptions,
  womenRingSizeOptions,
} from './productSizingOptionsWomen';
import { clothingSizeStandardOptions, shoesSizeStandardOptions } from './productSizingStandards';
import { ConditionTypeLabels, conditionTypes } from 'config/configListing';

type ShowPredicate = (values: ProductDefinitionDataForm) => boolean;

export type ProductDefinitionOption = {
  value: string;
  label: string;
  showPredicate?: ShowPredicate;
};

export function toKebapCase(str: string) {
  return str
    .replace(',', '')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();
}

function addKebabCaseId<
  T extends {
    label: string;
  },
>(v: T) {
  return {
    ...v,
    value: toKebapCase(v.label),
  };
}

export const KIDS_DEPARTMENTS = ['girls', 'boys'];
export const FEMALE_DEPARTMENTS = ['women', 'girls'];
export const MALE_DEPARTMENTS = ['men', 'boys'];

export const departmentOptions: Array<ProductDefinitionOption> = [
  'Men',
  'Women',
  'Boys',
  'Girls',
].map(department => ({
  value: toKebapCase(department),
  label: department,
}));

export const availabilityOptions: Array<ProductDefinitionOption> = [
  { value: 'all', label: 'Show all' },
  { value: 'hide_sold', label: 'Hide sold' },
];

export const categoryOptions: Array<ProductDefinitionOption> = [
  'Clothing',
  'Bags',
  'Shoes',
  'Jewellery',
  'Accessories',
].map(category => ({
  value: toKebapCase(category),
  label: category,
}));

export const productTypeOptions: Array<ProductDefinitionOption> = [
  // Clothing type
  {
    label: 'Outerwear',
    showPredicate: ({ category }) => category === 'clothing',
  },
  {
    label: 'Top',
    showPredicate: ({ category }) => category === 'clothing',
  },
  {
    label: 'Bottom',
    showPredicate: ({ category }) => category === 'clothing',
  },
  {
    label: 'Dress',
    showPredicate: ({ category, department }) =>
      ['women', 'girls'].includes(department) && category === 'clothing',
  },
  {
    label: 'Jumpsuit',
    showPredicate: ({ category, department }) =>
      ['women', 'girls'].includes(department) && category === 'clothing',
  },
  {
    label: 'Beachwear',
    showPredicate: ({ category }) => category === 'clothing',
  },
  {
    label: 'Sport',
    showPredicate: ({ category }) => category === 'clothing',
  },
  // Bag type
  {
    label: 'Backpack',
    showPredicate: ({ category }) => category === 'bags',
  },
  {
    label: 'Belt bag',
    showPredicate: ({ category }) => category === 'bags',
  },
  {
    label: 'Bucket Bag',
    showPredicate: ({ department, category }) =>
      category === 'bags' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Clutch Bag',
    showPredicate: ({ department, category }) =>
      category === 'bags' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Cross-body bag',
    showPredicate: ({ category }) => category === 'bags',
  },
  {
    label: 'Handbag',
    showPredicate: ({ category }) => category === 'bags',
  },
  {
    label: 'Travel',
    showPredicate: ({ category }) => category === 'bags',
  },
  {
    label: 'Mini Bag',
    showPredicate: ({ department, category }) =>
      category === 'bags' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Shoulder Bag',
    showPredicate: ({ department, category }) =>
      category === 'bags' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Tote Bag',
    showPredicate: ({ department, category }) =>
      category === 'bags' && FEMALE_DEPARTMENTS.includes(department),
  },
  // Shoes type

  {
    label: 'Sandals',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Espadrilles',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Mules & Clogs',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Sneakers',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Ballerinas',
    showPredicate: ({ department, category }) =>
      category === 'shoes' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Lace ups',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Ankle Boots',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Boots',
    showPredicate: ({ category }) => category === 'shoes',
  },
  {
    label: 'Heels',
    showPredicate: ({ department, category }) =>
      category === 'shoes' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Loafers',
    showPredicate: ({ department, category }) =>
      category === 'shoes' && FEMALE_DEPARTMENTS.includes(department),
  },
  // Jewellery type
  {
    label: 'Earrings',
    showPredicate: ({ department, category }) =>
      category === 'jewellery' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Necklace',
    showPredicate: ({ category }) => category === 'jewellery',
  },
  {
    label: 'Bracelet',
    showPredicate: ({ category }) => category === 'jewellery',
  },
  {
    label: 'Ankle Bracelet',
    showPredicate: ({ department, category }) =>
      category === 'jewellery' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Body Jewellery',
    showPredicate: ({ department, category }) =>
      category === 'jewellery' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Ring',
    showPredicate: ({ category }) => category === 'jewellery',
  },
  {
    label: 'Watch',
    showPredicate: ({ category }) => category === 'jewellery',
  },
  {
    label: 'Pins',
    showPredicate: ({ department, category }) =>
      category === 'jewellery' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Charm',
    showPredicate: ({ department, category }) =>
      category === 'jewellery' && FEMALE_DEPARTMENTS.includes(department),
  },
  // Accessories type
  {
    label: 'Sunglasses',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Belts',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Wallets',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Hats',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Scarf',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Gloves',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Hair Accessory',
    showPredicate: ({ department, category }) =>
      category === 'accessories' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Bag accessory',
    showPredicate: ({ category }) => category === 'accessories',
  },
  {
    label: 'Optical',
    showPredicate: ({ category }) => category === 'accessories',
  },
].map(addKebabCaseId);

export const conditionOptions: Array<ProductDefinitionOption> = conditionTypes.map(condition => ({
  value: condition,
  label: ConditionTypeLabels[condition],
}));

export const productSubtypeOptions: Array<ProductDefinitionOption> = [
  // Outerwear sub-type
  {
    label: 'Coat',
    showPredicate: ({ productType }) => productType === 'outerwear',
  },
  {
    label: 'Trench coat',
    showPredicate: ({ productType }) => productType === 'outerwear',
  },
  {
    label: 'Jacket',
    showPredicate: ({ productType }) => productType === 'outerwear',
  },
  {
    label: 'Puffer Jacket',
    showPredicate: ({ productType }) => productType === 'outerwear',
  },
  {
    label: 'Leather jacket',
    showPredicate: ({ productType }) => productType === 'outerwear',
  },
  {
    label: 'Cape',
    showPredicate: ({ productType }) => productType === 'outerwear',
  },

  // Top sub-type
  {
    label: 'Vest',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Blazer',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Sweater',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Cardigan',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Hoodie',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Sweatshirt',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'T-shirt',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Blouse',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Shirt',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Camisole',
    showPredicate: ({ productType }) => productType === 'top',
  },
  {
    label: 'Sports bra',
    showPredicate: ({ department, productType }) =>
      productType === 'top' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Crop Top',
    showPredicate: ({ department, productType }) =>
      productType === 'top' && FEMALE_DEPARTMENTS.includes(department),
  },

  // Bottom sub-type
  {
    label: 'Skirt',
    showPredicate: ({ department, productType }) =>
      productType === 'bottom' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Shorts',
    showPredicate: ({ productType }) => productType === 'bottom',
  },
  {
    label: 'Trousers',
    showPredicate: ({ productType }) => productType === 'bottom',
  },
  {
    label: 'Jeans',
    showPredicate: ({ productType }) => productType === 'bottom',
  },
  {
    label: 'Leggings',
    showPredicate: ({ productType }) => productType === 'bottom',
  },
  {
    label: 'Sweatpants',
    showPredicate: ({ productType }) => productType === 'bottom',
  },

  // Dress sub-type
  {
    label: 'Short dress',
    showPredicate: ({ department, productType }) =>
      productType === 'dress' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Midi dress',
    showPredicate: ({ department, productType }) =>
      productType === 'dress' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Long dress',
    showPredicate: ({ department, productType }) =>
      productType === 'dress' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Shirt dress',
    showPredicate: ({ department, productType }) =>
      productType === 'dress' && FEMALE_DEPARTMENTS.includes(department),
  },

  // Jumpsuit sub-type
  {
    label: 'Short jumpsuit',
    showPredicate: ({ department, productType }) =>
      productType === 'jumpsuit' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Midi jumpsuit',
    showPredicate: ({ department, productType }) =>
      productType === 'jumpsuit' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Long jumpsuit',
    showPredicate: ({ department, productType }) =>
      productType === 'jumpsuit' && FEMALE_DEPARTMENTS.includes(department),
  },

  // Beachwear sub-type
  {
    label: 'Bikini top',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Bikini bottom',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Bikini set',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'One-piece swimsuit',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Shorts',
    showPredicate: ({ department, productType }) => productType === 'beachwear',
  },
  {
    label: 'Beach dress',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Cover up',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Kaftans, Capes & Kimonos',
    showPredicate: ({ department, productType }) =>
      productType === 'beachwear' && FEMALE_DEPARTMENTS.includes(department),
  },

  // Sport sub-type
  {
    label: 'Sports bra',
    showPredicate: ({ department, productType }) =>
      productType === 'sport' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Leggings',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Shorts',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Top',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Skirt',
    showPredicate: ({ department, productType }) =>
      productType === 'sport' && FEMALE_DEPARTMENTS.includes(department),
  },
  {
    label: 'Sweatshirt',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Sweatpants',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Hoodie',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Jacket',
    showPredicate: ({ productType }) => productType === 'sport',
  },
  {
    label: 'Dress',
    showPredicate: ({ department, productType }) =>
      productType === 'sport' && FEMALE_DEPARTMENTS.includes(department),
  },
].map(productSubtype => ({
  ...productSubtype,
  value: toKebapCase(productSubtype.label),
}));

export const colorOptionsPredicate: ShowPredicate = ({ category }) =>
  Boolean(category && category !== 'jewellery');

export const sizeStandardOptions: Array<ProductDefinitionOption> = [
  ...clothingSizeStandardOptions,
  ...shoesSizeStandardOptions,
];

export const sizeOptions = [
  ...intWomenClothingSizeOptions,
  ...euWomenClothingSizeOptions,
  ...ukWomenClothingSizeOptions,
  ...usWomenClothingSizeOptions,
  ...itWomenClothingSizeOptions,
  ...euWomenShoeSizeOptions,
  ...ukWomenShoeSizeOptions,
  ...usWomenShoeSizeOptions,
  ...itWomenShoeSizeOptions,
  ...womenBeltSizeOptions,
  ...womenGloveSizeOptions,
  ...womenHatSizeOptions,
  ...womenRingSizeOptions,

  ...intMenClothingSizeOptions,
  ...euMenClothingSizeOptions,
  ...ukMenClothingSizeOptions,
  ...usMenClothingSizeOptions,
  ...itMenClothingSizeOptions,
  ...euMenShoesSizeOptions,
  ...ukMenShoesSizeOptions,
  ...usMenShoesSizeOptions,
  ...itMenShoesSizeOptions,
  ...menBeltSizeOptions,
  ...menGlovesSizeOptions,
  ...menHatSizeOptions,
  ...menRingSizeOptions,

  ...kidsClothingSizeOptions,
  ...kidsShoesSizeOptions,
  ...kidsBeltSizeOptions,
  ...kidsGlovesSizeOptions,
  ...kidsHatSizeOptions,
];
